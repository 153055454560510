


















import { RequestModel } from '@/models/Request.model';
import requestsRepository from '@/repository/modules/requests.repository';
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfoRequestNotaSimple from '@/components/Requests/nota-simple/info-request.vue';
import OptionsRequestNotaSimple from '@/components/Requests/nota-simple/options-request.vue';
import EmailHistoryComponent from '@/components/Requests/email-history-component.vue';

@Component({
  components: {
    InfoRequestNotaSimple,
    OptionsRequestNotaSimple,
    EmailHistoryComponent,
  },
})
export default class BaseRequests extends Vue {
  @Prop({ required: true, type: String })
  request_id!: string;

  dataRequest!: RequestModel | null;

  request_type: string | null = null;
  request_state: string | null = null;

  async mounted() {
    await this.loadRequest();
  }

  async loadRequest() {
    console.log('load');
    this.dataRequest = null;
    this.request_type = null;
    this.request_state = null;
    setTimeout(async () => {
      const requestData: RequestModel = await requestsRepository.getRequestComplete(this.request_id);
      this.dataRequest = requestData;
      this.request_type = requestData.request_type;
      this.request_state = requestData.request_state;
    }, 1);
  }

  get colorRequestState() {
    switch (this.request_state) {
      case 'in-process':
      case 'waiting-response-user':
        return 'warning';
      case 'finished':
        return 'success';
      default:
        return 'blue-gray';
    }
  }
}
