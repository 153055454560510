
























import { RequestModel } from '@/models/Request.model';
import { copyToClipboard, setterDate } from '@/plugins/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CustomersRepository from '@/repository/modules/customers.repository';

@Component
export default class InfoRequestNotaSimple extends Vue {
  @Prop({ required: true, type: RequestModel })
  dataRequest!: RequestModel;

  customer_id: string | null = null;
  customer_email = '--';

  request_info = {
    catastro: false,
    fecha_valor: '',
    inmueble_bloque: '',
    inmueble_direccion: '',
    inmueble_escalera: '',
    inmueble_municipio: '',
    inmueble_numero: '',
    inmueble_planta: '',
    inmueble_provincia: '',
    inmueble_puerta: '',
    nota_simple: false,
    referencia_express: false,
    tipo_finalidad: null,
  };

  @Watch('dataRequest', { immediate: true })
  updatedDataRequest() {
    this.customer_id = this.dataRequest.user_id;
    this.getCustomer();
    this.setRequestInfo(this.dataRequest.request_data);
  }

  setRequestInfo(infoDataRequest: string) {
    const infoFormated = JSON.parse(infoDataRequest);
    this.request_info = infoFormated;
    console.log(infoFormated);
  }

  async getCustomer() {
    const { customer } = await CustomersRepository.GetCustomerData(this.customer_id as string);
    this.customer_email = customer.user_email;
  }

  setterDate = setterDate;

  copyToClipboard = copyToClipboard;
}
